import { SIGN_IN_REQUEST, SIGN_IN_SUCCESS, SIGN_IN_FAILED, SIGN_OUT } from './ApplicationActions';

const defaultUser = {
  graphQLErrors: [{ message: null }],
};

// Pseudo reducer. Waiting for the refactoring
export default function user(state = defaultUser, action = {}) {
  switch (action.type) {
    case SIGN_IN_REQUEST:
      return defaultUser;

    case SIGN_IN_SUCCESS:
      return state;

    case SIGN_IN_FAILED: {
      if (action.error) {
        return action.error;
      }

      return {
        graphQLErrors: [{ message: 'Some unhandled error has occurred' }],
      };
    }

    case SIGN_OUT:
      return defaultUser;

    default:
      return state;
  }
}
