import React from 'react';
import './Preloader.scss';

const Preloader = () => (
  <div className="Preloader">
    <div className="spinner-border text-dark" role="status" >
      <span className="sr-only"> Loading...</span>
    </div>
  </div>
);

export default Preloader;
