import urls from '../../config';

export function isWidget() {
  return window.self !== window.top;
}

export function getWidgetProjectUrl() {
  return window.growityWidgetProjectUrl;
}

export function getMainLink() {
  return isWidget()
    ? `/${window.growityWidgetProjectUrl}`
    : '/';
}

export function getRecentActivityLink() {
  return `/${window.growityWidgetProjectUrl}/recent-activity`;
}

export function getIdeasLink() {
  return `/${window.growityWidgetProjectUrl}/ideas`;
}

export function getExpViewerLink() {
  return `/${window.growityWidgetProjectUrl}/exp-viewer`;
}

export function getFeaturesLink() {
  return `/${window.growityWidgetProjectUrl}/features`;
}

export function getLogoName() {
  return isWidget()
    ? `Growity / ${window.growityWidgetProjectUrl}`
    : 'Growity';
}

export function getDevMode() {
  return fetch(`${urls.exp}/projects/${window.growityWidgetProjectUrl}/get-dev-mode`, {
    credentials: 'include',
  })
    .then(data => data.ok && data.json());
}

export async function switchDevMode() {
  const { isDevModeOn, projectId } = await getDevMode();
  const nextRole = isDevModeOn ? 'editor' : 'developer';

  return fetch(`${urls.exp}/set-cookie?project=${projectId}&role=${nextRole}`, {
    credentials: 'include',
  })
    .then(data => data.ok && data.json())
    .then(data => data && { isDevModeOn: !isDevModeOn, ...data });
}

export async function setEditorCookiesLocally() {
  const { projectId } = await getDevMode();

  return fetch(`https://localhost:3080/set-cookie?project=${projectId}&role=editor`, {
    credentials: 'include',
  })
    .then(data => data.ok && data.json());
}

export function isLocalExpKeeperRunning() {
  return new Promise((resolve, reject) => {
    fetch('https://s.growity.me/update-projects-features', {
      method: 'GET',
    })
      .then(data => data.ok && resolve(true))
      .catch(error => reject(error));
  });
}

export function saveGrowityWidgetProjectUrl() {
  const link = new URL(document.location.href);
  const projectUrl = link.pathname.slice(1).split('/')[0];

  window.growityWidgetProjectUrl = projectUrl;
}

export function updateProjectsFeatures() {
  return fetch(`${urls.exp}/update-projects-features`, {
    method: 'GET',
  });
}
