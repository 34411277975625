import { SET_BREADCRUMBS } from './BreadcrumbsActions';

export default function breadcrumbs(state = [], action = {}) {
  switch (action.type) {
    case SET_BREADCRUMBS: {
      const lastCrumb = action.breadcrumbs[action.breadcrumbs.length - 1];
      lastCrumb.active = true;

      return [...action.breadcrumbs];
    }
    default:
      return state;
  }
}
