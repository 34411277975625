import { combineReducers } from 'redux';

import user from '../components/Application/ApplicationReducers';
import breadcrumbs from '../components/Breadcrumbs/BreadcrumbsReducers';
// import projectSystemInfo from './common/redux/projectSystemInfoReducer';
// import commonComponents from './common/redux/commonComponentsReducer';
import userAvatar from '../components/UserAvatar/UserAvatarReducer';

export default combineReducers({
  user,
  userAvatar,
  breadcrumbs,
  // projectSystemInfo,
  // commonComponents,
});
