import { GET_USER_AVATAR } from './UserAvatarActions';

const defaultUserAvatar = {
  isFetching: false,
  payload: {
    imageUrl: null,
    error: null,
  },
};

export default function userAvatar(state = defaultUserAvatar, action = {}) {
  switch (action.type) {
    case `${GET_USER_AVATAR}_REQUEST`:
    case `${GET_USER_AVATAR}_SUCCESS`:
    case `${GET_USER_AVATAR}_FAILED`:
      return action.payload;
    default:
      return state;
  }
}
