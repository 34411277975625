import { getUserAvatarActionCreator } from '../../common/utils/userHelpers';

export const GET_USER_AVATAR = 'UserAvatar/getUserAvatar';

export function getUserAvatar(email) {
  return getUserAvatarActionCreator({
    action: GET_USER_AVATAR,
    payload: email,
  });
}
