import React from 'react';
import ReactDOM from 'react-dom';

import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import logger from 'redux-logger';
import createHistory from 'history/createBrowserHistory';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloProvider } from 'react-apollo';
import Loadable from 'react-loadable';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { DragDropContextProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import rootReducer from './reducers/reducers';
// import { loadScript } from './common/utils/loadHelpers';
// import { initGoogleApi } from './common/gapi/googleApi';
import { getCookie } from './common/utils/cookiesHelpers';

import * as serviceWorker from './serviceWorker';
import Preloader from './components/Preloader/Preloader';

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/shards-dashboards.scss";

const Application = Loadable({
  loader: () => import(/* webpackChunkName: 'Application' */ './components/Application/Application.apollo'),
  loading: Preloader,
});

const uri = 'https://api.graph.cool/simple/v1/cj0h3wy6s1ar901223ufbdlhp';

const httpLink = new BatchHttpLink({
  uri,
  headers: { batch: 'true' },
  credentials: 'same-origin',
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const graphcoolToken = getCookie('graphcoolToken');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: graphcoolToken ? `Bearer ${graphcoolToken}` : '',
    },
  };
});

const link = authLink.concat(httpLink);

const cache = new InMemoryCache({
  dataIdFromObject: o => o.id,
  addTypename: true,
});

const client = new ApolloClient({
  link,
  cache: cache.restore(window.__APOLLO_STATE__), // eslint-disable-line no-underscore-dangle
  connectToDevTools: true,
  queryDeduplication: true,
});

const initialState = {};
const enhancer = composeWithDevTools(
  applyMiddleware(
    thunkMiddleware,
    logger,
  ),
);

const store = createStore(rootReducer, initialState, enhancer);

ReactDOM.render(
    <Provider store={store}>
      <ApolloProvider client={client}>
        <DragDropContextProvider backend={HTML5Backend}>
          <Application history={createHistory()} />
        </DragDropContextProvider>
      </ApolloProvider>
    </Provider>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
