import md5 from 'blueimp-md5';

export const isAdmin = user => user.role === 'ADMIN';

export const getUserAvatarActionCreator = ({ action, payload }) => async (dispatch) => {
  dispatch({
    type: `${action}_REQUEST`,
    payload: { isFetching: true },
  });

  const hashedEmail = md5(payload);
  const image = new Image();
  const src = `https://secure.gravatar.com/avatar/${hashedEmail}?s=80&d=404`;
  image.onload = () => {
    dispatch({
      type: `${action}_SUCCESS`,
      payload: {
        isFetching: false,
        payload: {
          imageUrl: src,
          error: null,
        },
      },
    });
  };

  image.onerror = () => {
    dispatch({
      type: `${action}_FAILED`,
      payload: {
        isFetching: false,
        payload: {
          imageUrl: null,
          error: 'image is not loaded',
        },
      },
    });
  };

  image.src = src;
};

export default {
  isAdmin,
  getUserAvatarActionCreator,
};
